$accordion-transition: 800ms ease-in-out;

.accordion-enter {
  height: 0;
  opacity: 0;
}

.accordion-enter-active {
  height: auto;
  opacity: 1;
  transition: all $accordion-transition;
}

.accordion-exit {
  height: auto;
  opacity: 1;
}

.accordion-exit-active {
  height: 0;
  opacity: 0;
  transition: all $accordion-transition;
}
